import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I veckans avsnitt snackas det om ARM-processorer, ett nytt sätt för Mozilla att tjäna pengar, Material UI, Neumorphism, WebBundles, nya PinePhones, Apple + Rust och mycket mer.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Nvidia köper ARM: `}<a parentName="li" {...{
          "href": "https://archive.vn/PLQr3#selection-1897.0-1899.83"
        }}>{`https://archive.vn/PLQr3#selection-1897.0-1899.83`}</a></li>
      <li parentName="ul">{`Mozilla Promoted Addons: `}<a parentName="li" {...{
          "href": "https://blog.mozilla.org/addons/2020/09/09/introducing-the-promoted-add-ons-pilot/"
        }}>{`https://blog.mozilla.org/addons/2020/09/09/introducing-the-promoted-add-ons-pilot/`}</a></li>
      <li parentName="ul">{`Apple siktar in sig på Rust: `}<a parentName="li" {...{
          "href": "https://twitter.com/oskargroth/status/1301502690409709568"
        }}>{`https://twitter.com/oskargroth/status/1301502690409709568`}</a></li>
      <li parentName="ul">{`WebBundles: `}<a parentName="li" {...{
          "href": "https://brave.com/webbundles-harmful-to-content-blocking-security-tools-and-the-open-web/"
        }}>{`https://brave.com/webbundles-harmful-to-content-blocking-security-tools-and-the-open-web/`}</a></li>
      <li parentName="ul">{`PinePhone + Manjaro: `}<a parentName="li" {...{
          "href": "https://forum.manjaro.org/t/pinephone-will-ship-with-manjaro-next/18369"
        }}>{`https://forum.manjaro.org/t/pinephone-will-ship-with-manjaro-next/18369`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`OTF-uppdateringar: `}<a parentName="li" {...{
          "href": "https://www.axios.com/scoop-open-technology-fund-sues-administration-for-20m-in-missing-funds-90b5420d-2f41-4e50-b58b-6a517af26cd6.html"
        }}>{`https://www.axios.com/scoop-open-technology-fund-sues-administration-for-20m-in-missing-funds-90b5420d-2f41-4e50-b58b-6a517af26cd6.html`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Material UI: `}<a parentName="li" {...{
          "href": "https://material-ui.com"
        }}>{`https://material-ui.com`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex Rust-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`Rust-servern levererar media`}</li>
      <li parentName="ul">{`Börjat nysta i frontend-frågorna`}<ul parentName="li">
          <li parentName="ul">{`Design System? Baserat på tråk-Material eller "heta" neumorphism?! `}</li>
          <li parentName="ul">{`GraphQL-client?! Apollo testas...`}</li>
          <li parentName="ul">{`Övergripande layout?!`}</li>
        </ul></li>
      <li parentName="ul">{`Börjat nysta i grundprinciperna, delmål`}<ul parentName="li">
          <li parentName="ul">{`1: Prestanda, renhet, basfunktioner`}</li>
          <li parentName="ul">{`2: Fler "borde finnas"-funktioner, basdelning (fediverse?), "flöden"`}</li>
          <li parentName="ul">{`3: Expansion (fediverse) och AI-trams/strössel`}</li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Förvirrande med omröstning om titel på flera plattformar`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      